import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import FeedbackComponent from '@/core/shared/feadback/feedback.component.vue';
import {IServiceHeader} from '@/types/service-header';
import AccordionComponent from '@/core/shared/accordion/accordion.component.vue';
import AccordionItemComponent from '@/core/shared/accordion-item/accordion-item.component.vue';

@Options({
    name: 'service-name-component',
    props: {
        serviceName: {
            type: [String, null],
           default: ''
        },
        organization: {
            type: [String, null],
            default: ''
        },
        registered: {
            type: Boolean,
            default: ''
        },
        payment: {
            type: Boolean,
            default: false
        },
        img: {
            type: [String, null],
        },
        structureId: {
            type: [String, null, Number, undefined],
            default: '',
        },
        serviceId: {
            type: [String, null, Number, undefined],
            default: '',
        },
        headers: {
            type: [Array, null]
        },
        staticImg :{
            type: Boolean,
            default: false
        }
    },
    components: {
        FeedbackComponent,
        AccordionComponent,
        AccordionItemComponent,
    }
})

export default class ServiceNameComponent extends Vue {
    public selectedVideo = 1;
    public locale$: any = locale;
    public locale: any = null;
    public localeElements: any = null;
    public localeElement: any = null;
    public localeService: any = null;
    public swiperOptions: any = {
        spaceBetween: 37,
        loop: false,
        navigation: {
            nextEl: '.next',
            prevEl: '.prev',
        },
    };
    public header: IServiceHeader = {
        title: null,
        serviceHeaderFiles: [],
        serviceHeaderUrls: [],
        id: null,
    };

    public clickHeader(item: IServiceHeader) {
        this.header = item;
        if (this.header.serviceHeaderUrls.length || this.header.serviceHeaderFiles?.length) {
            this.$refs.headerModal.openModal();
        }
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.services) {
                this.locale = res.services.serviceAboutCard;
                this.localeElements = res.home.search;
                this.localeService = res.services.specialService;
                this.localeElement = res.elements;
            }
        });
    }

    public $refs!: {
        feedbackModal: { clickModal: () => void; closeModal: () => void; },
        headerModal: { openModal: () => void; closeModal: () => void; }
    };


    get servicesActionsData(): Array<object> {
        return [
            {
                id: 1,
                icon: 'attention.svg',
                title: this.locale.userGuide,
            },
            {
                id: 2,
                icon: 'camera.svg',
                title: this.locale.videoGuide,
            },
            {
                id: 3,
                icon: 'reglament.svg',
                title: this.locale.regulations,
            },
            {
                id: 4,
                icon: 'contact.svg',
                title: this.locale.feedback,
            }
        ];
    }

    public clickModal() {
        this.$refs.feedbackModal.clickModal();
    }

    public clickModalHeader() {
        this.$refs.headerModal.closeModal();
    }

    public prevVideo() {
        if (this.selectedVideo != 1) {
            this.selectedVideo = this.selectedVideo - 1;
        }
    }

    public nextVideo() {
        if (this.header.serviceHeaderUrls.length > 1 && this.selectedVideo !== this.header.serviceHeaderUrls.length) {
            this.selectedVideo = this.selectedVideo + 1;
        }
    }

}
