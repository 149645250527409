import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'accordion-item-component',
    props: {
        question: {
            type: Object,
        },
    }
})
export default class AccordionItemComponent extends Vue {
    public active = false;
    public $refs!: {
        content: HTMLElement,
        wrapper: HTMLElement,
    };

    public itemClick() {
        let contentHeight = this.$refs.content.clientHeight,
            wrapperHeight = this.$refs.wrapper.clientHeight;
        if (contentHeight != wrapperHeight) {
            this.$refs.wrapper.style.maxHeight = `${contentHeight + 25}px`;
            let element = document.querySelector<HTMLElement>('.accordion-main .accordion-item__wrapper');
            if (element) {
                element.style.maxHeight = `${element.clientHeight + contentHeight + 25}px`;
            }
        }
        if (contentHeight < wrapperHeight) {
            this.$refs.wrapper.style.maxHeight = `0`;
            // let element = document.querySelector<HTMLElement>('.accordion-child .accordion-item__wrapper');
            // if (element) {
            //
            //     element.style.maxHeight = `0`;
            // }
        }
        this.active = !this.active;
        this.$emit('contentHeight', contentHeight + 25);

    }
}
