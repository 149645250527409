import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import AccordionItemComponent from '@/core/shared/accordion-item/accordion-item.component.vue';

@Options({
    name: 'accordion-component',
    components: {
        AccordionItemComponent
    },
})
export default class AccordionComponent extends Vue {
    public faqIndex: number | null = null;

    // public data = [];
    public toggle(ind: number) {
        if (this.faqIndex === ind) {
            this.faqIndex = null;
        } else {
            this.faqIndex = ind;
        }
    }

}
