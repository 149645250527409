<style src="./service-name.component.scss" lang="scss"></style>

<template>
  <div class="service-name">
    <v-card class="py-20 px-25">
      <div class="company-data d-flex align-center">
        <div class="company-data__logo d-md-block d-none">
          <img :src="img" alt="logo" v-if="!staticImg"/>
          <img :src="require(`@/assets/images/icons/${img}`)" alt="logo" v-else/>
        </div>
        <div class="company-data__info">
          <span>{{ organization }}</span>
          <h1>
            {{ serviceName }}
          </h1>
        </div>
      </div>
      <v-divider/>
      <div class="row align-center mt-20 d-none d-lg-flex">
        <div class="col-lg-4 ">
          <div class="row services-data">
            <div class="col-6">
              <div class="services-data__wrapper">
                <div class="services-data__icon">
                  <img :src="require(`@/assets/images/icons/identity-card.svg`)" alt="service">
                </div>
                <div class="services-data__info">
                  <div>{{ locale.service }}</div>
                  <span v-if="registered">{{ localeElements.registered }}</span>
                  <span v-else>{{ localeElements.noRegistered }}</span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="services-data__wrapper">
                <div class="services-data__icon">
                  <img :src="require(`@/assets/images/icons/coins.svg`)" alt="service">
                </div>
                <div class="services-data__info">
                  <div>{{ locale.stateCustoms }}</div>
                  <span v-if="payment">{{ localeElements.paid }}</span>
                  <span v-else>{{ localeElements.noPaid }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 d-lg-none d-block">
              <v-divider/>
            </div>
          </div>
        </div>
        <div class="col-lg-8 services-data__instructions">
          <div class="services-data__instructions__list">
            <template v-for="item in headers">
              <div
                  v-if="item.serviceHeaderFiles?.length!==1 || item.serviceHeaderUrls?.length !== 0"

                  :style="[{ cursor: (item.serviceHeaderFiles?.length || item.serviceHeaderUrls?.length)? 'pointer' : '' },
                { opacity: (item.serviceHeaderFiles?.length || item.serviceHeaderUrls?.length)? 1 : '0.5' }]"
                  @click="clickHeader(item)"
              >
                <img :src="item.icon" alt="icon" v-if="!item?.staticImg">
                <img :src="require(`@/assets/images/icons/${item.icon}`)" alt="logo" v-else/>
                <span>{{ item.title }}</span>
              </div>
              <div v-else
                   :style="[{ cursor: (item.serviceHeaderFiles?.length || item.serviceHeaderUrls?.length)? 'pointer' : '' },
                { opacity: (item.serviceHeaderFiles?.length || item.serviceHeaderUrls?.length)? 1 : '0.5' }]">
                <a
                    :href="item.serviceHeaderFiles[0]?.url"
                    :download="item.serviceHeaderFiles[0]?.url">
                  <img :src="item.icon" alt="icon">
                  {{ item.title }}
                </a>
              </div>
            </template>

            <div @click="clickModal()" style="cursor: pointer">
              <img :src="require(`@/assets/images/icons/contact.svg`)" alt="contact">
              <span> {{ locale.feedback }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-center mt-20 d-flex d-lg-none">
        <div class="col-12">
          <accordion-component>
            <accordion-item-component>
              <template v-slot:title>
                <div>
                  <img class="mr-12" src="@/assets/images/icons/service-name.svg" alt="">
                  <span>Xidmət haqqında</span>
                </div>
              </template>
              <template v-slot:content>
                <div>
                  <div class="row services-data">
                    <div class="col-12 p-0 d-lg-none d-block">
                      <v-divider/>
                    </div>
                    <div class="col-6 border">
                      <div class="services-data__wrapper">
                        <div class="services-data__icon">
                          <img :src="require(`@/assets/images/icons/identity-card.svg`)" alt="service">
                        </div>
                        <div class="services-data__info">
                          <span v-if="registered">{{ localeElements.registered }}</span>
                          <span v-else>{{ localeElements.noRegistered }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="services-data__wrapper">
                        <div class="services-data__icon">
                          <img :src="require(`@/assets/images/icons/coins.svg`)" alt="service">
                        </div>
                        <div class="services-data__info">
                          <span v-if="payment">{{ localeElements.paid }}</span>
                          <span v-else>{{ localeElements.noPaid }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 p-0 d-lg-none d-block">
                      <v-divider/>
                    </div>
                  </div>
                </div>
                <div class=" services-data__instructions">
                  <div class="services-data__instructions__list">
                    <template v-for="item in headers">
                      <div
                          v-if="item.serviceHeaderFiles?.length!==1 || item.serviceHeaderUrls.length !== 0"

                          :style="[{ cursor: (item.serviceHeaderFiles.length || item.serviceHeaderUrls.length)? 'pointer' : '' },
                { opacity: (item.serviceHeaderFiles.length || item.serviceHeaderUrls.length)? 1 : '0.5' }]"
                          @click="clickHeader(item)"
                      >
                        <img :src="item.icon" alt="icon">
                        <span>{{ item.title }}</span>
                      </div>
                      <div v-else
                           :style="[{ cursor: (item.serviceHeaderFiles.length || item.serviceHeaderUrls.length)? 'pointer' : '' },
                { opacity: (item.serviceHeaderFiles.length || item.serviceHeaderUrls.length)? 1 : '0.5' }]">
                        <a
                            :href="item.serviceHeaderFiles[0]?.url"
                            :download="item.serviceHeaderFiles[0]?.url">
                          <img :src="item.icon" alt="icon">
                          {{ item.title }}
                        </a>
                      </div>
                    </template>

                    <div @click="clickModal()" style="cursor: pointer">
                      <img :src="require(`@/assets/images/icons/contact.svg`)" alt="contact">
                      <span> {{ locale.feedback }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </accordion-item-component>
          </accordion-component>
        </div>

      </div>
    </v-card>
    <feedback-component
        ref="feedbackModal"
        :serviceId="serviceId"
        :structureId="structureId"
    />
  </div>
  <div class="service-information">
    <v-modal ref="headerModal"
             :title="header.title"
             :size="header.serviceHeaderUrls.length?'lg':'md'">
      <template v-slot:body>

        <div class="service-header">
          <div class="service-header__instruction "
               :class="{'mb-50':header.serviceHeaderUrls.length}"
               v-if="header.serviceHeaderFiles.length">
            <div class="service-header__instruction-item"
                 v-for="item in header.serviceHeaderFiles"
                 :key="item.id">
              <a :href="item.url" :download="item.url">
                <div>{{ item.title }}</div>
              </a>
            </div>
          </div>
          <div class="service-header__sub  d-flex justify-between mb-20" v-if="header.serviceHeaderUrls.length">
            <div class="service-header__sub-name">
              Video təlimat
            </div>
            <div class="service-header__sub-count" v-if="header.serviceHeaderUrls.length>1">
              {{ selectedVideo }}/{{ header.serviceHeaderUrls.length }}
            </div>
          </div>
          <div class="service-header__video" v-if="header.serviceHeaderUrls.length">
            <div class="service-header__video-container">
              <template v-for="(item, index)  in header.serviceHeaderUrls">
                <div class="service-header__video-item"
                     v-if="index+1 ===selectedVideo"
                >
                  <iframe :src="item.url" title="YouTube video player" frameborder="0"></iframe>

                </div>
              </template>
              <div
                  v-if="header.serviceHeaderUrls.length>1" class="service-header__video-prev"><span
                  @click="prevVideo"></span></div>
              <div v-if="header.serviceHeaderUrls.length>1" class="service-header__video-next"><span
                  @click="nextVideo"></span></div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="text-center">
          <v-btn @click="clickModalHeader" color="black">{{ localeElement.close }}</v-btn>
        </div>
      </template>
    </v-modal>

  </div>
</template>

<script src="./service-name.component.ts"></script>
