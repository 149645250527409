import {Vue} from 'vue-class-component';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {container} from 'tsyringe';

export class Router extends Vue {
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);

    public getServiceData() {
        let componentName = '';
        if (String(this.$route.name).indexOf('services/') !== -1) {
            componentName = String(this.$route.name).slice(9, String(this.$route.name).length);
        } else {
            componentName = String(this.$route.name);
        }
        this.sandboxCommon.getServiceCommonName({
            componentName: componentName,
            token: localStorage.getItem('mainToken')
        });
    }
}

