import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class CommonSandbox {
    private store = store;

    constructor() {
    }

    getCommonApplicantData(payload: any): any {
        return this.store.dispatch('getApplicantData', payload);
    }

    getCommonDocumentSeriesType(payload: number): any {
        return this.store.dispatch('getRadioRegistrationDocumentSeriesType', payload);
    }

    getNumbers(payload: any): void {
        this.store.dispatch('getNumbers', payload);
    }

    getServiceCommonName(payload:{componentName:string, token:string | null}): any {
        return this.store.dispatch('getServiceCommonName', payload);
    }

    getCommonServiceHeader(payload:string): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }

    getCitizenship(): any {
        return this.store.dispatch('getCitizenship');
    }

    getCommonCitizenship(): any {
        return this.store.dispatch('getCommonCitizenship');
    }

    getDocumentSeriesType(): any {
        return this.store.dispatch('getDocumentSeriesType');
    }

    getOrganPersonType(): any {
        return this.store.dispatch('getOrganPersonType');
    }

    getCommonOrganPersonType(payload:number): any {
        return this.store.dispatch('getCommonOrganPersonType', payload);
    }

    getRadioOrganizationName(payload: any): any {
        return this.store.dispatch('getRadioOrganizationName', payload);
    }

    getUserNewDetails(): void {
        this.store.dispatch('getUserNewDetails');
    }

}
