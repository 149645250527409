import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "accordion-item__wrapper",
  ref: "wrapper"
}
const _hoisted_2 = {
  class: "accordion-item__content",
  ref: "content"
}
const _hoisted_3 = { class: "accordion-element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wrapper", {'wrapper__active':_ctx.active}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["accordion-item", {'accordion-item__active':_ctx.active}])
    }, [
      _createElementVNode("div", {
        class: "accordion-item__title",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.itemClick && _ctx.itemClick(...args)))
      }, [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "content")
            ], 512)
          ], 512)
        ]),
        _: 3
      }),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "element")
      ])
    ], 2)
  ], 2))
}